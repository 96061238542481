import {Questions} from 'acquaint-api/constants/Questions';
import { IDDescriptionPair } from "@/types/generic/IDDescriptionPair";

export default function findQuestionText(id:Number){
    const questionText = Questions.find((question:IDDescriptionPair)=>{ return question.id == id})
    if (questionText){
        return questionText.description;
    } else{
        return "";
    }
}