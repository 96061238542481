export enum SystemContactTypes {
    Standard,
    User,
    Solicitor,
    LocalAuthority,
    Publication,
    MortgageProvider,
    EstateAgent,
    Applicant,
    Landlord,
    Vendor,
    Contractor,
    UtilitySupplier,
    Bank,
    ManagingAgent,
    Guarantor,
    Referee,
    Company,
    BlockManagementCompany,
    InsuranceCompany,
    AuctionHouse,
    FinancialAdvisor,
    BoardSupplier,
    GeneralBusinssContact
}
