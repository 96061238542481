import { baseAlert } from "./baseAlert"

/** Displays a SweetAlert prompting the user to answer a question 
 * @param text - The question text to display to the user
 * @param confirmButtonText - The text to display on the Confirm button
 * @param denyButtonText - The text to display on the Deny button */
const displayQuestionAlert = (text: string, confirmButtonText: string = 'Yes', denyButtonText: string = 'No') => {
    return baseAlert.fire({
        text: text,
        icon: 'question',
        confirmButtonText: confirmButtonText,
        denyButtonText: denyButtonText,
        showDenyButton: true,
        heightAuto: false,
    })
}

export { displayQuestionAlert }