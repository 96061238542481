import Cookies from "js-cookie"

/** Attempts to read a cookie with the passed name and deserialize it from json into the request type 
 * If no cookie exists, undefined will be returned */
export const readCookie = <T>(cookieName :string) => {
    // Sanity check the cookie name to avoid errors
    if (!cookieName) {
        throw 'cookieName must be entered';
    }

    const cookie = Cookies.get(cookieName) as string;

    // Cookie doesn't exist so return undefined to avoid deserialize errors
    if (!cookie) {
        return undefined;
    }

    try {
        // Attempt to deserialize the cookie into the passed type
        return JSON.parse(cookie) as T;
    } catch {
        /* If this fails as the cookie isn't valid json or doesn't match the request type, return undefined
         * We want to handle the error so the app continues to work,
         * To aid troubleshooting, the error should be written to the console */
        console.error(`Unable to deserialize cookie ${cookieName} into the requested type`);
        return undefined;
    }
}