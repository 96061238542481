<script setup>
import { useFavicon } from "@vueuse/core";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { isPropertyButtonUrl } from "./utils/system/EnvironmentReader";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
  inject
} from "vue";

import { useRoute } from "vue-router";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

const vm = getCurrentInstance();

const title = vm.proxy.$appInfo.title;
const screen = reactive({ getScreenSizeInfo: {} });
screen.getScreenSizeInfo = getScreenSizeInfo();

function screenSizeChanged() {
  screen.getScreenSizeInfo = getScreenSizeInfo();
}

onMounted(() => {
  subscribe(screenSizeChanged);
  setFavicon();
});

onBeforeUnmount(() => {
  unsubscribe(screenSizeChanged);
});

const cssClasses = computed(() => {
  return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
});

const route = useRoute();

const appClass = computed(() => {
  const loginRoutes = ['login-form', 'reset-password', 'reset-password-confirmation'];
  return loginRoutes.includes(route.name) ? ['app-login'] : ['app-content'];
});

const setFavicon = () => {
  // To avoid confusion with utility switching customers, ensure favicon is set appropiately
  const favicon = isPropertyButtonUrl() ? 'pb.ico' : 'favicon.ico';
  useFavicon(favicon)
}

const helpNavigator = inject('HelpNavigator');

window.addEventListener('keydown', (e) => {
  if (e.key === 'F1' && !isPropertyButtonUrl()) {
    helpNavigator.LoadHelpTopicFromCurrentRoute();
  }
});

</script>

<template>
  <div id="root" :class="appClass">
    <div :class="cssClasses">
      <component :is="$route.meta.layout" :title="title" :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge">
        <div class="content">
          <router-view></router-view>
        </div>
        <template #footer>
        </template>
      </component>
    </div>
  </div>
</template>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app-login {
  background-image: linear-gradient(to bottom, rgba(223, 242, 249, 0), rgba(223, 242, 249, 1)),
    url('../public/swirl.jpg') !important;
}

.app {
  display: flex;
  height: 100%;
  width: 100%;
}

.app-content {
  background-image: linear-gradient(to bottom, rgba(223, 242, 249, 0), rgba(223, 242, 249, 1)),
    url('../public/swirl.jpg') !important;
}
</style>
