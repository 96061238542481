<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Tenants" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 255.8 240" style="enable-background:new 0 0 255.8 240;" xml:space="preserve" height="100%" width="100%">
        <g>
            <path d="M235.3,191.7c0-0.1,0-0.3,0-0.4l0,0c0,0,0,0,0,0c0-0.3-0.1-0.5-0.1-0.8c-2-12.7-10.9-23.2-22.8-28c-4.2,3.1-9.4,4.9-15,4.9
		s-10.7-1.8-14.9-4.9c-5.9,2.4-11.1,6.3-15,11.1c11.9,5.5,21.5,13.7,27.4,23.5h35.3l0,0C233,197.1,235.3,194.7,235.3,191.7
		L235.3,191.7z" />
            <path d="M204.6,159c2.2-1,4.2-2.3,6-4.1s3.1-3.8,4-6c1-2.3,1.5-4.8,1.5-7.4c0-2.6-0.5-5-1.5-7.4c-0.9-2.3-2.3-4.3-4-6
		c-1.7-1.7-3.7-3.1-6-4.1c-2.3-1-4.8-1.5-7.3-1.5c-2.5,0-5,0.5-7.3,1.5c-2.2,1-4.2,2.3-6,4.1c-1.7,1.7-3.1,3.8-4,6
		c-1,2.3-1.5,4.8-1.5,7.4c0,2.6,0.5,5,1.5,7.4c0.9,2.3,2.3,4.3,4,6c1.7,1.7,3.7,3.1,6,4.1c2.3,1,4.8,1.5,7.3,1.5
		C199.9,160.4,202.3,159.9,204.6,159z" />
            <path
                d="M68.2,197.1c5.7-9.4,14.8-17.4,26.1-22.9c-4-5.1-9.4-9.3-15.7-11.8c-4.2,3.1-9.4,4.9-15,4.9s-10.7-1.8-14.9-4.9
		c-11.7,4.8-20.5,15.1-22.6,27.6c-0.2,0.5-0.2,1.1-0.2,1.6c0,3,2.4,5.4,5.4,5.4c0,0,0,0,0.1,0l0,0L68.2,197.1L68.2,197.1z" />
            <path d="M71,159c2.2-1,4.2-2.3,6-4.1c1.7-1.7,3.1-3.8,4-6c1-2.3,1.5-4.8,1.5-7.4c0-2.6-0.5-5-1.5-7.4c-0.9-2.3-2.3-4.3-4-6
		c-1.7-1.7-3.7-3.1-6-4.1c-2.3-1-4.8-1.5-7.3-1.5c-2.5,0-5,0.5-7.3,1.5c-2.2,1-4.2,2.3-6,4.1c-1.7,1.7-3.1,3.8-4,6
		c-1,2.3-1.5,4.8-1.5,7.4c0,2.6,0.5,5,1.5,7.4c0.9,2.3,2.3,4.3,4,6c1.7,1.7,3.7,3.1,6,4.1c2.3,1,4.8,1.5,7.3,1.5
		C66.2,160.4,68.7,159.9,71,159z" />
            <path d="M197.6,230.3c0-0.2,0-0.5,0-0.7l0,0c0,0,0,0,0-0.1c0-0.5-0.1-1-0.2-1.4c-3.7-22.7-19.5-41.5-40.9-50.2
		c-4.8-1.7-8.1-5.9-5.8-11.1l0,0c0.9-0.8,1.8-1.5,2.6-2.4c3.1-3.1,5.5-6.8,7.2-10.8c1.7-4.2,2.6-8.6,2.6-13.2c0-4.6-0.9-9-2.6-13.2
		c-1.7-4.1-4.1-7.7-7.2-10.8c-3.1-3.1-6.7-5.6-10.7-7.3c-4.1-1.8-8.5-2.7-13.1-2.7c-4.5,0-8.9,0.9-13.1,2.7c-4,1.7-7.6,4.2-10.7,7.3
		c-3.1,3.1-5.5,6.8-7.2,10.8c-1.7,4.2-2.6,8.6-2.6,13.2c0,4.6,0.9,9,2.6,13.2c1.7,4,4.1,7.7,7.2,10.8c0.2,0.2,0.5,0.4,0.7,0.7
		c0,0,0,0,0,0c3.5,5.1,2.1,11.4-3.7,13c-21,8.6-36.6,27.1-40.5,49.4c-0.3,0.9-0.5,1.9-0.5,2.9c0,5.4,4.3,9.7,9.6,9.7
		c0,0,0.1,0,0.1,0l0,0h116.6c0,0,0,0,0,0C193.4,239.8,197.5,235.5,197.6,230.3L197.6,230.3z" />
            <path d="M209.7,68.1V26c0-1.9-1.6-3.5-3.5-3.5h-35.6c-1.9,0-3.5,1.6-3.5,3.5v6.2L129.8,0.7c-1.1-0.9-2.7-0.9-3.8,0L1.4,105.9
		c-1.6,1.4-1.8,3.8-0.5,5.4l14,16.6c1.4,1.6,3.8,1.8,5.4,0.5l106.7-89.6c0.6-0.5,1.4-0.5,2,0l106.7,89.6c1.6,1.4,4,1.1,5.4-0.5
		l14-16.6c1.4-1.6,1.1-4-0.5-5.4L209.7,68.1L209.7,68.1z" />
        </g>
    </svg>
</template>