import moment from 'moment';
import { useCurrentUserStore } from '@/stores/CurrentUserStore';
import { CurrentUser } from '@/types/user/CurrentUser';
import { ref } from 'vue';

export async function TimeStamps(bodyText: string) {
    const currentUser = ref<CurrentUser>();
    const currentUserStore = useCurrentUserStore();
    const timeDate = moment(Date.now()).format('DD/MM/YYYY HH:mm:ss');

    currentUser.value = await currentUserStore.getCurrentUserDetails();

    /* Only append a new line if there's something already in the text 
     * Otherwise, this can look strange for the end user */
    if (bodyText) {
        bodyText += '\n';
    }

    return `${bodyText}${timeDate} ${currentUser.value?.name} :`;
}