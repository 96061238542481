import { IDDescriptionPair } from "@/types/generic/IDDescriptionPair";
import { SupportCaseReason } from "@/types/utility-switching/SupportCaseReason";

/** Maps the passed Support Case Reasons array to an array of IDDescriptionPair models
 * @param caseReasons The SupportCaseReasons array to map
 * @returns An array of IDDescriptionPair models, populated with mapped data */
const mapSupportCaseReasonsToIDDescriptionPairArray = (caseReasons :SupportCaseReason[]) :IDDescriptionPair[] => {
    return caseReasons.map<IDDescriptionPair>(item => {
        return { id: item.sortIndex, description: item.value }
    });
}

export { mapSupportCaseReasonsToIDDescriptionPairArray }