import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { AgencyAccount } from "@/types/switches/AgencyAccount";
import { Mutex } from "async-mutex";
import { defineStore } from "pinia";
import { inject, ref } from "vue";

export const useAgencyAccountStore = defineStore('agencyAccountStore', () => {
    const mutex = new Mutex();

    const cachedAgencyAccount = ref<AgencyAccount>();
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient;

    const getAccount = async () :Promise<AgencyAccount> => {
        const account = await mutex.runExclusive(async () => {
            if (!cachedAgencyAccount.value) {
                cachedAgencyAccount.value = await getAccountFromApi();
            }

            return cachedAgencyAccount.value;
        });

        return account;
    }

    const getAccountFromApi = async () :Promise<AgencyAccount> => {
        return await apiClient.httpGet<AgencyAccount>('app/utilityswitching/account');
    }

    const clearCache = () => cachedAgencyAccount.value = undefined;

    return { getAccount, clearCache }
});