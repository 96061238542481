export class RecordNavigator {
    private _records :number[] = [];
    private _currentIndex :number = 0;

    /** Caches the passed records and the current position in the array */
    cacheRecords(records :number[], currentIndex :number = 0) {
        this._records = records;
        this._currentIndex = currentIndex;
    }

    /** Gets the id of the next record */
    getNextRecord() {
        // To avoid errors only go to next record if there's a record to navigate to
        if (this.isLastRecord() || this.isIndexOutOfBounds(this._currentIndex + 1)) {
            return this._records[this._currentIndex];
        }

        // Move to the next record
        this._currentIndex++;

        return this._records[this._currentIndex];
    }

    /** Gets the id of the previous record */
    getPreviousRecord() {
        // To avoid errors only go to next record if there's a record to navigate to
        if (this.isFirstRecord() || this.isIndexOutOfBounds(this._currentIndex - 1)) {
            return this._records[this._currentIndex];
        }

        // Move to the previous record
        this._currentIndex--;

        return this._records[this._currentIndex];
    }

    /** Checks if the current record is the last record in the array */
    isLastRecord() {
        return this._currentIndex === this._records.length -1;
    }

    /** Checks if the current record is the first record in the array */
    isFirstRecord() {
        return this._currentIndex === 0;
    }

    /** Gets the current position in the array */
    currentPosition() {
        return this._currentIndex + 1;
    }

    /** Gets the total count of records in the array */
    totalCount() {
        return this._records.length;
    }

    /** Checks and returns whether the cache is empty */
    isEmpty() {
        return (this._records.length === 0);
    }

    /** Clears all previous records */
    clear() {
        this._records = [];
    }

    /** Checks whether the passed index is out of bounds */
    private isIndexOutOfBounds(index :number) {
        return index < 0 || index > this._records.length - 1 || this._records.length === 0;
    }
}