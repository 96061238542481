import { CountryCode } from "bright-logic-core/src/CountryCode";

/** Gets currency format for a specific to use with DevExpress components
 * @param countryCodeId id of the country to retrieve currency format for */
export const getCurrencyFormat = (countryCodeId: number | undefined) => {
    switch (countryCodeId) {
        case CountryCode.Ireland:
            return "€#,##0.00";
        case CountryCode.UK:
        case CountryCode.Guernsey:
        case CountryCode.Jersey:
            return "£#,##0.00";
        default:
            return '';
    }
}
/** Converts the passed number into a currency string based on the passed country code */
export const formatNumberIntoCurrency = (value: number, countryCodeId: number | undefined) => {
    const poundsFormatConverter = new Intl.NumberFormat(
        'en-GB', { style: 'currency', currency: 'GBP', }
    );

    const eurosFormatConverter = new Intl.NumberFormat(
        'en', { style: 'currency', currency: 'EUR', }
    );

    switch (countryCodeId) {
        case CountryCode.Ireland:
            return eurosFormatConverter.format(value);
        case CountryCode.UK:
        case CountryCode.Guernsey:
        case CountryCode.Jersey:
            return poundsFormatConverter.format(value)
        default:
            return value.toString();
    }
}