import { Mutex } from "async-mutex";
import { defineStore } from "pinia";
import { inject, reactive, ref } from "vue";
import { CurrentUser } from '@/types/user/CurrentUser'
import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { AuthenticationCookieManager } from "@/services/AuthenticationCookieManager";

export const useCurrentUserStore = defineStore('currentUser', () => {
    const mutex = new Mutex();

    const cachedCurrentUser = ref<CurrentUser>();
    const cachedSitePrefix = ref('');
    const acquaintApiClient = inject('AcquaintApiClient') as AcquaintApiClient;
    const authenticationCookieManager = reactive(new AuthenticationCookieManager)

    /** Gets details for the current user */
    const getCurrentUserDetails = async () => {
        const currentUser = await mutex.runExclusive(async () => {
            // To improve app performance only call api if user's details aren't currently cached
            if(!cachedCurrentUser.value) {
                cachedCurrentUser.value = await getCurrentUserFromApi();
            }

            return cachedCurrentUser.value;
        });

        return currentUser;
    }

    /** Clears the cache */
    const clearCache = () => {
        cachedCurrentUser.value = undefined;
        cachedSitePrefix.value = '';
    }

    /** Gets the user's details from the Acquaint Api */
    const getCurrentUserFromApi = async () => {
        return await acquaintApiClient.httpGet<CurrentUser>('app/user/current');
    }

    /** Retrieves the site prefix for the current database */
    const getSitePrefix = async () => {
        const sitePrefix = await mutex.runExclusive(() => {
            // To avoid unnecessary processing, only read cookie if we haven't already got the site prefix
            if (!cachedSitePrefix.value) {
                // Site prefix is stored in a secure cookie during the login process
                const siteDetails = authenticationCookieManager.getSiteDetails();
               
                cachedSitePrefix.value = siteDetails?.sitePrefix ? siteDetails?.sitePrefix : '';
            }
            
            return cachedSitePrefix.value;
        });

        return sitePrefix;
    };

    return { getCurrentUserDetails, clearCache, getSitePrefix }
});