import { EnergyTypes } from '@/enums/utility-switching/EnergyTypes';

export function FindEnergyTypeText(propertyEnergyType:number){
    switch(propertyEnergyType){
        case EnergyTypes.DualFuel:
            return "Dual Fuel";
        case EnergyTypes.ElectricityOnly:
            return "Electricity Only";
        case EnergyTypes.GasOnly:
            return "Gas Only";
        default: 
            return "Electricity Only";
    }
}