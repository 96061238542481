<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Occupier" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 235.8 240" style="enable-background:new 0 0 235.8 240;" xml:space="preserve" width="100%" height="100%">
        <g>
            <path d="M193.2,62.8V24c0-1.8-1.4-3.2-3.2-3.2h-32.8c-1.8,0-3.2,1.4-3.2,3.2v5.7L119.7,0.6c-1-0.9-2.5-0.9-3.5,0L1.2,97.6
		c-1.5,1.2-1.7,3.5-0.4,4.9l12.9,15.3c1.2,1.5,3.5,1.7,4.9,0.4L117,35.7c0.5-0.4,1.3-0.4,1.8,0l98.3,82.6c1.5,1.2,3.7,1.1,4.9-0.4
		l12.9-15.3c1.2-1.5,1.1-3.7-0.4-4.9L193.2,62.8L193.2,62.8z" />
            <path d="M203,227.8c0-0.3,0-0.6,0-0.9l0,0c0,0,0-0.1,0-0.1c-0.1-0.6-0.1-1.2-0.3-1.8c-4.6-28.5-24.4-52.1-51.3-63
		c-6-2.1-10.2-7.3-7.2-14l0,0c1.1-0.9,2.2-1.9,3.3-3c3.9-3.9,6.9-8.5,9-13.6c2.2-5.2,3.3-10.8,3.3-16.6c0-5.8-1.1-11.4-3.3-16.6
		c-2.1-5.1-5.2-9.6-9-13.6c-3.9-3.9-8.4-7-13.4-9.1c-5.2-2.2-10.7-3.3-16.4-3.3s-11.2,1.1-16.4,3.3c-5,2.1-9.5,5.2-13.4,9.1
		c-3.9,3.9-6.9,8.5-9,13.6c-2.2,5.2-3.3,10.8-3.3,16.6c0,5.8,1.1,11.4,3.3,16.6c2.1,5.1,5.2,9.6,9,13.6c0.3,0.3,0.6,0.6,0.9,0.8
		c0,0,0,0,0,0c4.4,6.4,2.7,14.3-4.6,16.3c-26.4,10.9-46,34-50.8,62c-0.4,1.2-0.6,2.4-0.6,3.7c0,6.7,5.4,12.2,12.1,12.2
		c0.1,0,0.1,0,0.1,0l0,0h146.4c0,0,0,0,0,0C197.8,239.8,203.1,234.4,203,227.8L203,227.8z" />
        </g>
    </svg>
</template>