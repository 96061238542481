import { ref } from "vue";
import { defineStore } from "pinia";
import { Mutex } from "async-mutex";
import { readCookie } from "@/utils/cookies/JsonCookieReader";
import { writeCookie } from "@/utils/cookies/JsonCookieWriter";
import { useCurrentUserStore } from "./CurrentUserStore";

export const useHomeCarouselStore = defineStore('homeCarouselStore', () => {
    const mutex = new Mutex();

    const currentUserStore = useCurrentUserStore();
    const carouselShown = ref<boolean>();

    /** Constructs filter name for currently logged in user and site prefix */
    const getCookieName = async () => {
        const filterName = 'x-home-carousel-displayed';
        const currentUser = await currentUserStore.getCurrentUserDetails();
        const sitePrefix = await currentUserStore.getSitePrefix();

        return [filterName, sitePrefix, currentUser.id].filter(item => !!item).join('-');
    };

    const checkIfCarouselHasBeenShown = async () => {
        if(!carouselShown.value){
            carouselShown.value = readCookie<boolean>(await getCookieName());
        }

        return carouselShown.value ? carouselShown.value:false;
    }
    
    /** Stores the current Appointment List filters so filters are remembered 
     * when user navigates back to the Appointment List */
    const carouselHasBeenShown = async () => {
        carouselShown.value = true
        writeCookie<boolean>(await getCookieName(), true);
    }

    return { checkIfCarouselHasBeenShown, carouselHasBeenShown }
});