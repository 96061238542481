export enum CertificateType {
    GasSafteyCertificate = 1,
    ElectrictySafteyCertificate = 2,
    PortableApplianceTesting = 3,
    FireAlarmCertificate = 4,
    RentGuarantee = 5,
    LandlordRegistration = 6,
    PropertyLicence = 7,
    PropertyLicenceHMO = 8,
    ApplianceGuarantee = 9,
    LegionellaRiskAssessment = 10,
    SmokeAlarmCertificate = 11,
    CarbonMonoxideAlarm = 12,
    RightToRent = 13,
    ContractorLiabilityInsurance = 14,
    ProofOfID = 15,
    EPCMinimumException = 16,
    EPC = 17,
    Chimney = 18,
    OilBoiler = 19,
    RTBRegistration = 20
}