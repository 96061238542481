import { EnergyDetails } from "@/types/switches/EnergyDetails"

const getDefaultEnergyDetailsModel = () :EnergyDetails => {
    return {...{
        nightSaverMeterRead: '',
        twentyfourHMeterRead: '',
        gasGPRNNum: '',
        gasMeterRead: '',
        readingDate: '',
        payAsYouGo: false,
        energyType: 'Electricity Only',
        nightMeterRead: '',
        peakMeterRead: '',
        dayMeterMeterRead: '',
        mprn: '',
        electricMeterType: 0
    }}
}

export { getDefaultEnergyDetailsModel }