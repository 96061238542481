import { LandlordPersonalInfo } from "@/types/switches/LandlordPersonalInfo";
import { PersonalInfo } from "@/types/switches/PersonalInfo";
import { Contacts } from "acquaint-api/models/Contacts";

/*** Maps the passed Acquaint Contact model to a Landlord Personal Info model
 * @param contact - The Acquaint Contact model to map
 * @returns LandlordPersonalInfo model populated with mapped data */
const mapAcquaintContactToLandlordPersonalInfo = (contact: Contacts): LandlordPersonalInfo => {
    return {
        id: contact.id,
        title: contact.titleID,
        firstName: contact.firstNames,
        lastName: contact.lastName,
        email: contact.email,
        number: contact.mobileTelephone ?? contact.homeTelephone,
        address: {
            countryCode: contact.homeCountryCode,
            country: contact.homeCountry,
            propertyName: contact.homeProperty,
            street: contact.homeStreet,
            locality: contact.homeLocality,
            town: contact.homeTown,
            region: contact.homeRegion,
            postCode: contact.homePostcode
        }
    }
}

/*** Maps the passed Acquaint Contact model to the passed Personal Info model
 * @param contact- The Acquaint Contact model to map
 * @param personalInfo - The Personal Info model to map data to */
const mapAcquaintContactToPersonalInfo = (contact: Contacts, personalInfo: PersonalInfo) => {
    personalInfo.firstName = contact.firstNames;
    personalInfo.lastName = contact.lastName;
    personalInfo.title = contact.titleID;
    personalInfo.birthDate = contact.birthDate;
    personalInfo.email = contact.email;
    personalInfo.number = contact.mobileTelephone;
    personalInfo.id = contact.id;
}

export { mapAcquaintContactToLandlordPersonalInfo, mapAcquaintContactToPersonalInfo }