import { defineStore } from "pinia";
import { readCookie } from '@/utils/cookies/JsonCookieReader';
import { writeCookie } from '@/utils/cookies/JsonCookieWriter';
import { ref } from "vue";
import { useCurrentUserStore } from "./CurrentUserStore";
import { CurrentUser } from '@/types/user/CurrentUser'

export const useRecentRecordsStore = defineStore('recentRecords', () => {
    const currentUserStore = useCurrentUserStore();
    const contactsIds = ref<number[]>([]);
    const propertiesIds = ref<number[]>([]);
    const currentUser = ref<CurrentUser>();

    const getUserDetails = async ()=>{
        if(!currentUser.value){
            currentUser.value = await currentUserStore.getCurrentUserDetails();
        }
    };

    const addContactRecentRecord = async (id:string)=>{
        await getUserDetails();

        // If a user closes the app and returns to it, we need repopulate this store list using cookie.
        if(contactsIds.value.length==0){
            contactsIds.value = await readContactRecordsCookie();
        }

        // If a contact record is viewed and is viewed again, we need to update its position in the list by removing
        // its id from the list than adding it to the end.
        if(contactsIds.value.find((contactId)=>{return String(contactId) == id})){
            contactsIds.value = contactsIds.value.filter((contactId)=>{return String(contactId) != id});
        }
        
        // We don't want to have too many records being displayed so we remove the earliest contact id to be added.
        // Because the array's first item is the most recently accessed, we remove the last item.
        if(contactsIds.value.length>=10){
            contactsIds.value.pop();
        }

        // We add each item to the start of the array so that on the recent records screen, the most recently accessed
        // record appears at the top.
        contactsIds.value.unshift(Number(id));
        writeCookie<number[]>(`recentlyAccessedContacts${currentUser.value?.id}`, contactsIds.value);
    };

    const addPropertyRecentRecord = async (id:string)=>{
        await getUserDetails();

        if(propertiesIds.value.length==0){
            propertiesIds.value = await readPropertyRecordsCookie();
        }

        if(propertiesIds.value.find((propertyId)=>{return String(propertyId) == id})){
            propertiesIds.value = propertiesIds.value.filter((propertyId)=>{return String(propertyId) != id});
        }

        if(propertiesIds.value.length>=10){
            propertiesIds.value.pop();
        }

        propertiesIds.value.unshift(Number(id));
        writeCookie<number[]>(`recentlyAccessedProperties${currentUser.value?.id}`, propertiesIds.value);
    };

    const readContactRecordsCookie = async ()=> { 
        await getUserDetails();
        const records:number[] = readCookie(`recentlyAccessedContacts${currentUser.value?.id}`)??[]; 
        return records 
    };

    const readPropertyRecordsCookie = async ()=> { 
        await getUserDetails();
        const records:number[] = readCookie(`recentlyAccessedProperties${currentUser.value?.id}`)??[]; 
        return records 
    };

    const clearCache = ()=>{
        // We need to clear these arrays when a user logs out so that when if another user log ins on the same device afterwards,
        // the records of the first user don't appear any longer. We also need to reset the currentUser ref to ensure the each user's
        // details are fetched each time they log in so that if multiple users log into the same pc, their cookies are read and updated.
        contactsIds.value = [];
        propertiesIds.value = [];
        currentUser.value = undefined;
    };

    return { addContactRecentRecord, addPropertyRecentRecord, readContactRecordsCookie, readPropertyRecordsCookie, clearCache }
})