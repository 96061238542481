import { baseAlert } from "./baseAlert";

export const tenantSwitchConfirmAddress = (mprnAddress: string, width:string) => {
    return baseAlert.fire({
        icon:'question',
        html: '<span>Please confirm that this is the correct property address:</span><p>' + mprnAddress,
        color: 'black',
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        showConfirmButton: true,
        showDenyButton: true,
        width: width,
    });
}