import { Mutex } from "async-mutex";
import { defineStore } from "pinia";
import { inject, reactive } from "vue";
import { SystemCode } from '@/types/system/SystemCode';
import { CodeType } from "acquaint-api/constants/CodeType";
import { AcquaintApiClient } from "@/services/AcquaintApiClient";

export const useSystemCodesStore = defineStore('systemCodesStore', () => {
    const mutex = new Mutex();

    let systemCodesCache = reactive<SystemCode[]>([]);
    const acquaintApiClient = inject('AcquaintApiClient') as AcquaintApiClient;

    /** Retrieves all system codes for the passed code type */
    const getSystemCodesByCodeType = async (codeType :CodeType) => {
        const systemCodes = await mutex.runExclusive(async () => {
            // To improve app performance, only call retrieve system codes from the api if the cache is empty
            if (systemCodesCache.length === 0) {
                systemCodesCache = await getSystemCodesFromApi();
            }

            // As a fail safe, ensure the system code cache has been populated
            if (systemCodesCache.length === 0) {
                throw 'No systems codes found in cache';
            }

            const requestedSystemCodes = systemCodesCache.filter((item :SystemCode) => item.type == codeType);

            if (!requestedSystemCodes) {
                throw `No system codes found matching code type ${codeType}`
            }

            // To make this function work with generic filter components it needs to return an array of IDDescription models
            return requestedSystemCodes.map((item) => ({id: item.code, description: item.description }));
        });
        
        return systemCodes;
    }

    /** Retrieves the System Code Description for the passed code type and id */
    const getSystemCodeDescriptionByCodeTypeAndID = async (codeType: CodeType, id: Number) => {
        const systemCodeDescription = await mutex.runExclusive(async () => {
            // To improve app performance, only call retrieve system codes from the api if the cache is empty
            if (systemCodesCache.length === 0) {
                systemCodesCache = await getSystemCodesFromApi();
            }

            // As a fail safe, ensure the system code cache has been populated
            if (systemCodesCache.length === 0) {
                throw 'No systems codes found in cache';
            }

            // The Description we are looking for should be on the first item we find which matches the CodeType and ID
            const systemCode = systemCodesCache.find((item: SystemCode) => item.type == codeType && item.code == id);

            // As a fail safe, ensure we have found the correct system code
            if (!systemCode) {
                throw `No system code found matching code type ${codeType} and id ${id}`
            }

            return systemCode.description;
        });

        return systemCodeDescription;
    }

    /** Retrieves all system codes from the Acquaint Api */
    const getSystemCodesFromApi = async () => {
        return await acquaintApiClient.httpGet<SystemCode[]>('app/system/codes');
    }

    return { getSystemCodesByCodeType, getSystemCodeDescriptionByCodeTypeAndID }
});