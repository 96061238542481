<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 159.8 240" style="enable-background:new 0 0 159.8 240;" xml:space="preserve" width="100%" height="100%">
        <g>
            <path d="M79.9,240c11.6,0,21.7-4.8,26.4-11.8H53.4C58.3,235.2,68.3,240,79.9,240z M117,205.1H42.7c-3.6,0-6.6,3.3-6.6,7.4
		c0,4.1,3,7.3,6.6,7.3H117c3.6,0,6.5-3.3,6.5-7.3S120.6,205.1,117,205.1z M117,183.7H42.7c-3.6,0-6.6,3.3-6.6,7.4s3,7.4,6.6,7.4H117
		c3.6,0,6.5-3.3,6.5-7.4C123.5,187.1,120.6,183.7,117,183.7z M79.9,0C35.7,0,0,35.8,0,79.8c0,29.9,20.8,51.3,34.7,75.3
		c3.4,5.9,5.8,21.4,5.8,21.4h78.7c0,0,2.3-15.6,5.7-21.4c14-24,34.8-45.4,34.8-75.3C159.8,35.7,124,0,79.9,0L79.9,0z M127.8,94
		c-13.8,18.4-22.2,41-24.9,67l-0.2,2.4H91.8l0.3-2.9c2-20.9,7.6-39.8,16.4-56.4h-0.2l-1.3,0c-3.3-0.2-7.9-1.3-12.3-5.4
		c-4.1,3.4-8.8,5.3-13.5,5.4c-5.6,0-10.9-2.2-15.7-6.5c-5,3.6-10.3,5.6-15.3,5.3c12.5,25,15.1,50.2,15.6,57.6l0.2,2.8H55.2l-0.2-2.5
		c-0.7-9.1-4.3-40.8-22.6-67l-0.1,0l-0.7-1c-0.1-0.1-0.1-0.2-0.2-0.4l-0.2-0.3c-1.1-2.4-0.5-5.3,1.7-6.9c2.7-1.8,6-1.1,7.6,1.3v0.1
		c2.8,3.2,5.8,4.9,8.8,5.3c3.8,0.3,8.3-1.5,13-5.7c1-0.9,2.3-1.5,3.7-1.5c1.8,0.1,3.1,0.8,4,1.9c3.7,4.3,7.4,6.5,10.8,6.5
		c4.3-0.1,7.8-3.3,10-5.9c1-1.2,2.6-2,4.2-2c1.8,0,3.4,0.9,4.4,2.5c1.6,2.4,4.2,5.2,8.1,5.5h0.1c4.3,0,8.3-2.2,11.5-6
		c1.9-2.1,5.4-2.5,7.5-0.8C129.2,88.3,129.5,91.7,127.8,94L127.8,94z" />
        </g>
    </svg>
</template>