import { PropertyData } from '@/types/properties/PropertyData';
import { Address } from '@/types/generic/Address';
import { Properties } from 'acquaint-api/models/Properties'

/** Maps the passed Property data to an Address model
 * @param property The property model to map data from 
 * @returns Address model with mapped data */
const mapPropertyToAddress = (property: PropertyData): Address => {
    return {
        countryCode: property.countryCode,
        postCode: property.postcode,
        propertyName: property.propertyName,
        street: property.street,
        locality: property.locality,
        town: property.town,
        region: property.region,
        country: property.country
    }
}

/** Maps the passed model Address onto the passed Property model
 * @param address The Address model containing data to map
 * @param property The Property model to map address data to
 * @returns Property model populated with mapped data */
const mapAddressToProperty = (address: Address, property: PropertyData): PropertyData => {
    property.countryCode = address.countryCode;
    property.postcode = address.postCode;
    property.propertyName = address.propertyName;
    property.street = address.street;
    property.locality = address.locality;
    property.town = address.town;
    property.region = address.region;

    return property;
}

/** Concatenate the passed Property model to a single address string
 * @param Property model to concatenate address for */
const mapPropertyToConcatenatedString = (property: Properties) => {
    return [property.propertyName, property.street, property.locality,
            property.town, property.region, property.postcode]
            .filter(item => !!item)
            .join(', ');
}

export { mapPropertyToAddress, mapAddressToProperty, mapPropertyToConcatenatedString }