import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { Mutex } from "async-mutex";
import { defineStore } from "pinia";
import { inject, ref } from "vue";
import { UserPermissions } from '@/types/user/permissions/UserPermissions'

export const useUserPermissionsStore = defineStore('userPermissions', () => {
    const mutex = new Mutex();
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient;
    const cachedUserPermissions = ref<UserPermissions>();

    /** Permissions for the currently logged in user */
    const getUserPermissions = async () => {
        const permissions = await mutex.runExclusive(async () => {
            // If we haven't cached the user's permissions before, we need to retrieve them from the api
            if (!cachedUserPermissions.value) {
                cachedUserPermissions.value = await getUserPermissionsFromApi();
            }
    
            return cachedUserPermissions.value;
        });

        return permissions;
    };

    /** Calls the Acquaint Api to get permissions for the current user */
    const getUserPermissionsFromApi = async () => {
        return await apiClient.httpGet<UserPermissions>('/app/user/permissions')
    }

    /** Clears the cached user permissions */
    const clearCache = () => {
        cachedUserPermissions.value = undefined;
    }

    return { getUserPermissions, clearCache}
});