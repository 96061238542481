<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" height="100%" width="100%" viewBox="0 0 283 240" style="enable-background:new 0 0 283 240;" xml:space="preserve">
        <g>
            <path d="M232,75.3V28.8c0-2.1-1.7-3.9-3.9-3.9h-39.3c-2.1,0-3.9,1.7-3.9,3.9v6.8L143.6,0.8c-1.2-1-3-1-4.2,0L1.5,117.1
		c-1.8,1.5-2,4.2-0.5,5.9l15.5,18.4c1.5,1.8,4.2,2,5.9,0.5l118-99.2c0.6-0.5,1.6-0.5,2.2,0l118,99.1c1.8,1.5,4.4,1.3,5.9-0.5
		l15.5-18.4c1.5-1.8,1.3-4.4-0.5-5.9L232,75.3L232,75.3z" />
            <path d="M139,57.7l-96.7,80.5c-0.9,0.7-1.4,1.8-1.4,2.9v95.1c0,2.1,1.7,3.8,3.8,3.8h58.3c2.1,0,3.8-1.7,3.8-3.8v-62.7
		c0-2.1,1.7-3.8,3.8-3.8h61.6c2.1,0,3.8,1.7,3.8,3.8v62.7c0,2.1,1.7,3.8,3.8,3.8h58.3c2.1,0,3.8-1.7,3.8-3.8v-95.1
		c0-1.1-0.5-2.2-1.4-2.9l-96.7-80.5C142.5,56.5,140.5,56.5,139,57.7L139,57.7z" />
        </g>
    </svg>
</template>