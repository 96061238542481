<script setup lang="ts">


</script>
<template>
    <svg viewBox="0 0 590.97 265.01">
        <polygon class="logo-cls-2" points="133.72 226.29 46.85 170.21 46.85 77.93 133.78 132.61 133.72 226.29" />
        <polygon class="logo-cls-3" points="218.83 174.53 133.72 226.29 133.78 132.57 218.7 80.51 218.83 174.53" />
        <polyline class="logo-cls-1" points="133.14 26.2 218.7 80.51 133.88 132.5 46.95 77.87 133.14 26.2" />
        <polyline class="logo-cls-3"
            points="133 26.37 67.16 65.81 101.13 66.61 101.74 87.94 135.18 68.15 156.85 82.52 190.82 84.05 190.33 62.46 133 26.37" />
        <polygon class="logo-cls-2"
            points="218.77 106.95 201.86 133.8 185.02 126.94 184.98 160.94 163.83 173.59 147 200.66 164.25 207.76 218.83 174.53 218.77 106.95" />
        <polygon class="logo-cls-3"
            points="118.7 216.6 100.93 186.36 118.88 179.78 82.89 156.56 82.91 133.77 64.92 104.03 46.85 110.51 46.85 170.21 118.7 216.6" />
        <g>
            <path class="logo-cls-3"
                d="M270.84,154.88c-.96,.22-1.77,.34-2.47,.37-.6,.1-1.3,.14-1.9,.14-1.54,0-2.86-.42-3.96-1.25-1-.87-1.84-2.16-2.43-3.94-1.69,2.07-3.61,3.61-5.71,4.66-2.15,1.05-4.57,1.54-7.19,1.54-4.28,0-7.65-1.46-10.09-4.41-2.43-2.91-3.65-7.03-3.65-12.26,0-4.62,1.08-8.3,3.25-11.12,2.19-2.8,5.96-5.26,11.31-7.39,1.13-.43,2.64-.99,4.53-1.64,5.09-1.87,7.64-4.24,7.64-7.23v-5.05c0-3.24-.66-5.58-2.03-7.06-1.32-1.48-3.51-2.2-6.58-2.2s-5.61,.97-7.31,2.86c-1.68,1.9-2.54,4.75-2.64,8.53h-6.13v-1.31c0-4.64,1.48-8.32,4.42-11.04,2.96-2.72,6.89-4.12,11.89-4.12,2.95,0,5.43,.4,7.45,1.15,2.02,.76,3.62,1.9,4.83,3.51,.86,1.07,1.46,2.33,1.81,3.78,.37,1.46,.55,3.57,.55,6.34v35.68c0,3.06,.27,4.91,.8,5.66,.49,.68,1.52,1.05,3.01,1.05h.61v4.77Zm-10.87-32.44c-9.05,3.61-14.64,6.5-16.83,8.72-2.17,2.2-3.25,5.05-3.25,8.54s.76,6.05,2.29,7.99c1.53,1.97,3.62,2.98,6.27,2.98,3.93,0,6.84-1.5,8.72-4.42,1.88-2.97,2.79-7.59,2.79-13.89v-9.93Z" />
            <path class="logo-cls-3"
                d="M314.35,134.23c-.47,7.28-2.12,12.82-5,16.56-2.9,3.74-6.87,5.61-11.94,5.61-5.85,0-10.22-2.69-13.26-8.08-2.96-5.39-4.49-13.29-4.49-23.72s1.53-18.5,4.54-23.84c3-5.31,7.48-7.98,13.5-7.98,4.7,0,8.49,1.67,11.33,4.99,2.86,3.29,4.39,7.87,4.62,13.72h-6.11c-.1-3.96-1.07-7.11-2.83-9.47-1.73-2.3-4.01-3.45-6.82-3.45-3.76,0-6.6,2.23-8.61,6.71-1.99,4.44-2.99,10.9-2.99,19.32s1,14.63,3.01,19.23c2.03,4.53,4.83,6.85,8.4,6.85,3.01,0,5.41-1.44,7.22-4.32,1.79-2.89,2.84-6.93,3.14-12.12h6.29Z" />
            <path class="logo-cls-3"
                d="M353.85,149.45c-1.42,2.39-3.14,4.16-5.15,5.36-2.04,1.2-4.47,1.77-7.23,1.77-5.99,0-10.47-2.63-13.43-7.94-2.96-5.27-4.42-13.33-4.42-24.12s1.39-19.06,4.18-24.07c2.84-5,7.26-7.51,13.39-7.51,2.69,0,5.03,.64,7.06,1.91,1.99,1.26,3.91,3.22,5.6,5.95v-6.25h6.3v97.61h-6.3v-42.71Zm0-24.6c0-9.52-.9-16.35-2.77-20.39-1.83-4.06-4.86-6.06-9.07-6.06s-7.02,2-8.87,6.09c-1.83,4.06-2.75,10.77-2.75,20.03s.97,15.73,2.86,19.93c1.89,4.28,4.85,6.37,8.95,6.37s7.14-1.99,8.96-5.9c1.79-3.92,2.69-10.65,2.69-20.06Z" />
            <path class="logo-cls-3"
                d="M380.23,94.36v40.77c0,6.38,.62,10.53,1.87,12.48,1.34,1.91,3.63,2.86,6.95,2.86,3.09,0,5.86-1.03,8.32-3.19,2.53-2.1,3.76-4.36,3.76-6.78v-46.13h6.46v60.3h-5.76v-6.5c-1.99,2.77-4.13,4.8-6.43,6.12-2.32,1.3-4.94,1.97-7.89,1.97-4.79,0-8.29-1.52-10.49-4.5-2.15-3-3.25-7.81-3.25-14.51v-42.87h6.47Z" />
            <path class="logo-cls-3"
                d="M454.18,154.88c-.93,.22-1.75,.34-2.4,.37-.67,.1-1.32,.14-1.93,.14-1.58,0-2.89-.42-3.97-1.25-1.03-.87-1.85-2.16-2.44-3.94-1.67,2.07-3.58,3.61-5.74,4.66-2.14,1.05-4.53,1.54-7.17,1.54-4.29,0-7.69-1.46-10.1-4.41-2.42-2.91-3.65-7.03-3.65-12.26,0-4.62,1.09-8.3,3.27-11.12,2.15-2.8,5.92-5.26,11.31-7.39,1.07-.43,2.59-.99,4.48-1.64,5.1-1.87,7.67-4.24,7.67-7.23v-5.05c0-3.24-.67-5.58-2-7.06-1.32-1.48-3.55-2.2-6.6-2.2s-5.61,.97-7.31,2.86c-1.68,1.9-2.55,4.75-2.65,8.53h-6.12v-1.31c0-4.64,1.48-8.32,4.42-11.04,2.94-2.72,6.91-4.12,11.87-4.12,2.94,0,5.45,.4,7.44,1.15,2.08,.76,3.66,1.9,4.87,3.51,.82,1.07,1.41,2.33,1.79,3.78,.36,1.46,.54,3.57,.54,6.34v35.68c0,3.06,.25,4.91,.78,5.66,.52,.68,1.52,1.05,3.05,1.05h.57v4.77Zm-10.85-32.44c-9.04,3.61-14.64,6.5-16.79,8.72-2.2,2.2-3.27,5.05-3.27,8.54s.74,6.05,2.3,7.99c1.51,1.97,3.61,2.98,6.22,2.98,3.96,0,6.92-1.5,8.73-4.42,1.88-2.97,2.81-7.59,2.81-13.89v-9.93Z" />
            <path class="logo-cls-3" d="M465.08,83.16v-10.5h6.93v10.5h-6.93Zm.16,71.5v-60.3h6.77v60.3h-6.77Z" />
            <path class="logo-cls-3"
                d="M514.54,154.66v-40.75c0-6.35-.62-10.51-1.91-12.46-1.3-1.92-3.6-2.89-6.91-2.89-3.08,0-5.88,1.03-8.35,3.14-2.48,2.12-3.72,4.41-3.72,6.84v46.11h-6.51v-60.3h5.86v6.51c1.94-2.8,4.1-4.87,6.36-6.13,2.33-1.31,4.94-1.96,7.91-1.96,4.78,0,8.3,1.53,10.44,4.56,2.19,2.99,3.32,7.83,3.32,14.51v42.82h-6.49Z" />
            <path class="logo-cls-3"
                d="M549.77,154.97c-.72,.2-1.48,.35-2.24,.42-.76,.13-1.61,.18-2.56,.18-3.6,0-6.04-.9-7.36-2.64-1.31-1.71-1.95-5.93-1.95-12.67v-40.48h-5.95v-5.43h5.95v-17.14h6.11v17.14h8v5.43h-8v41.77c0,4.04,.32,6.56,1.02,7.51,.67,.93,1.97,1.4,3.87,1.4,.37,0,.85-.03,1.37-.07,.5-.07,1.09-.16,1.74-.19v4.77Z" />
            <g>
                <path class="logo-cls-3"
                    d="M393.74,178.08c-.12-1.45-.66-2.53-1.48-3.31-.89-.78-2.08-1.13-3.57-1.13-1.93,0-3.36,.53-4.38,1.63-1.01,1.08-1.51,2.64-1.51,4.73s.5,3.59,1.51,4.67c.97,1.08,2.45,1.62,4.37,1.62,1.42,0,2.51-.32,3.36-.96,.85-.66,1.42-1.61,1.67-2.86h10.34c-.5,3.23-2.02,5.71-4.62,7.37-2.61,1.67-6.17,2.51-10.75,2.51-5.13,0-8.99-1.05-11.6-3.11-2.61-2.08-3.91-5.18-3.91-9.23s1.3-7.18,3.91-9.24c2.61-2.07,6.47-3.14,11.61-3.14,4.78,0,8.51,.92,11.15,2.69,2.59,1.81,3.98,4.41,4.15,7.77h-10.24Z" />
                <path class="logo-cls-3"
                    d="M424.97,168.23c2.01,0,3.62,.12,4.79,.33,1.23,.18,2.18,.5,2.86,.91,.86,.5,1.5,1.21,1.95,2.08,.46,.87,.69,1.88,.69,3.03,0,1.4-.34,2.61-1.01,3.66-.7,1.06-1.61,1.81-2.8,2.28,1,.44,1.72,.96,2.15,1.62,.4,.64,.65,1.76,.75,3.37v.73c.12,3.09,.52,4.78,1.21,5.12v.37h-9.96c-.29-.5-.45-1.47-.51-2.9,0-.83-.03-1.48-.09-1.94-.1-.84-.4-1.46-.83-1.79-.49-.35-1.27-.53-2.38-.53h-5.37v7.16h-9.02v-23.5h17.56Zm-8.54,10.19h5.23c1.34,0,2.33-.16,2.88-.5,.53-.33,.82-.86,.82-1.64s-.29-1.3-.76-1.6c-.54-.29-1.61-.47-3.16-.47h-5v4.21Z" />
                <path class="logo-cls-3"
                    d="M439.06,191.73v-23.5h13.27l3.92,14.33,4.21-14.33h13.08v23.5h-8.27v-18.34l-5.51,18.34h-6.97l-5.48-18.34v18.34h-8.25Z" />
            </g>
        </g>
    </svg>
</template>
<style>
.logo-cls-1 {
    fill: #fff;
}

.logo-cls-2 {
    fill: #bad2ed;
}

.logo-cls-3 {
    fill: #212d53;
}
</style>