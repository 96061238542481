import { ColorRGBToWin32 } from "bright-logic-core/src/colours";

/*** Converts the passed hex colour to a Win32 colour
 * @param hex the hex colour to convert
 * @returns The Win32 value of the converted colour */
const hexToWin32Color = (hex: string) => {
    const rgbValues = hexToRgb(hex);

    /* Weirdly, the rgb values have to passed in the wrong order. 
     * I think this is a bug with the ColorRGBToWin32 function, 
     * as the rgb values returned by the hexToRgb function appear correct */
    return rgbValues ? ColorRGBToWin32(rgbValues.b, rgbValues.g, rgbValues.r) : null;
}

/*** Converts the passed hex colour to an rgb object 
 * @param hex the hex colour to convert
 * @returns Object containing the rgb values of the converted colour */
const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

/** Converts the passed rgb color string to a Win32 color */
const rgbToWin32 = (colour: string) => {
    const colourSubstring = colour.substring(4, colour.length - 1);
    const colourArray = colourSubstring.split(',');

    return ColorRGBToWin32(parseInt(colourArray[2]),parseInt(colourArray[1]) , parseInt(colourArray[0]));
}

export { hexToRgb, hexToWin32Color, rgbToWin32 }