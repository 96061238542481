import Swal from "sweetalert2";

export const baseAlert = Swal.mixin({
    customClass: {
        icon: 'acquaint-swal-icon',
        container: 'acquaint-swal-container',
        popup: 'acquaint-swal-popup',
        htmlContainer: 'acquaint-swal-html-container',
        confirmButton: 'acquaint-swal-confirm-button'
    },
    heightAuto: false
});