import { standardInfoAlert } from '@/alerts/standardInfoAlert';

export const DisplayCompanyAlert = async ()=>{
    const companyPrompt = await standardInfoAlert('Your database is setup with more than one company. Please confirm which company this property is assigned to.');

    if(companyPrompt.isConfirmed || companyPrompt.isDismissed){
        return true;
    }

    return false;
};