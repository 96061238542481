import { defineStore } from "pinia";
import { reactive } from "vue";
import { RecordNavigator } from '../../utils/navigation/RecordNavigator'

export const useContactRecordNavigationStore = defineStore('contactRecordNavigationStore', () => {
    /** Helper class used to navigate the records. 
     * Whilst the store could just expose this, I've decided to encapsulate this
     * so that the implementation of the store can be changed without affecting other 
     * stores/components that use the navigator class */
    const recordNavigator = reactive<RecordNavigator>(new RecordNavigator)

    /** Cache passed array of contact ids */
    const cacheContacts = (contacts :number[], currentIndex :number = 0) => {
        recordNavigator.cacheRecords(contacts, currentIndex)
    } 

    /** Gets the next contact in the array */
    const getNextContactId = () => recordNavigator.getNextRecord();

    /** Gets the previous contact in the array */
    const getPreviousContactId = () => recordNavigator.getPreviousRecord();

    /** Checks whether the current contact is the first contact in the array */
    const isFirstContact = () => recordNavigator.isFirstRecord();

    /** Checks whether the current contact is the last contact in the array */
    const isLastContact = () => recordNavigator.isLastRecord();

    /** Gets the current position in the array */
    const currentPosition = () => recordNavigator.currentPosition();

    /** Gets the total count of records in the array */
    const totalCount = () => recordNavigator.totalCount();

    /** Checks whether the cache is empty */
    const isEmpty = () => recordNavigator.isEmpty();

    /** Clears the cached array of contact ids */
    const clear = () => recordNavigator.clear();

    return { cacheContacts, getNextContactId, getPreviousContactId, isFirstContact, isLastContact, currentPosition, totalCount, isEmpty, clear }
});