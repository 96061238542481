import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './assets/css/dx.material.Acquaint.css';
import './assets/css/fontawesome-all.css';
import 'vue-tel-input/vue-tel-input.css';

import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App.vue";
import appInfo from "./app-info";
import { AcquaintApiClientPlugin } from './services/AcquaintApiClient';
import { createPinia } from 'pinia';
import { AcquaintApiNextClientPlugin } from './services/AcquaintApiNextClient';
import { HelpNavigatorPlugin } from './services/HelpNavigator';

themes.initialized(() => {
    const app = createApp(App);
    const pinia = createPinia();

    app.use(router);
    app.use(AcquaintApiClientPlugin);
    app.use(AcquaintApiNextClientPlugin);
    app.use(HelpNavigatorPlugin);
    app.use(pinia);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});