<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Keys" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 356.3 240" style="enable-background:new 0 0 356.3 240;" xml:space="preserve" width="100%" height="100%">
        <g>
            <path d="M354.7,150.5l-0.2-0.2l0,0l-1.5-1.3l0,0l0,0l-5.6-4.6l-13.7-3.4l-3.6-10.8l-10.8-8.9l-6.7,1.4l-1.6-10.8l-13.2,1.5
		l-1.8-11.4l-8.9-6.7L277,86.5l-9.5,0.5l-0.4-8.5l-10.1-8.3l1.4-1.7l0,0c1-1.2,1.5-2.8,1.3-4.4c-0.2-1.6-0.9-3-2.2-4l-3.3-2.7
		c0.5-3.8,0.6-7.6,0.2-11.5c-0.6-6.5-2.5-12.8-5.5-18.6c-3.1-6-7.4-11.3-12.7-15.6c-5.3-4.3-11.3-7.5-17.8-9.4
		c-6.3-1.9-12.8-2.5-19.3-1.9c-6.5,0.6-12.8,2.5-18.6,5.5c-3.4,1.8-6.5,3.9-9.4,6.4c4.6-1.8,9.4-3,14.3-3.4c6.7-0.6,13.5,0,20,2
		c6.5,2,12.5,5.1,17.8,9.3c5.5,4.4,9.9,9.8,13.3,16c8.4,15.7,8.3,34.5-0.2,49.9l1.6,3c1.4,2.6,1.2,5.6-0.3,7.9l96.3,79.2l22.6-2.8
		L354.7,150.5L354.7,150.5z M352.4,170.1c-0.4,0.5-1,0.8-1.6,0.8c-0.5,0-1-0.2-1.4-0.5L245.8,84.6c-0.9-0.7-1-2.1-0.3-3
		c0.7-0.9,2.1-1,3-0.3l103.6,85.7C353,167.8,353.2,169.2,352.4,170.1L352.4,170.1z" />
            <path d="M111.7,68.4C111.7,68.4,111.7,68.3,111.7,68.4c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.2-0.1-0.3-0.2-0.5
		c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.3-0.4-0.5
		c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.4-0.3-0.6-0.5
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.2-0.7-0.3
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.5-0.2-0.8-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2,0-0.3-0.1-0.5-0.1
		c-0.1,0-0.2,0-0.3,0c-0.2,0.4-0.5,0.9-0.7,1.3c-3.6,7.7-3.8,15.1-0.6,20.7c1.6,2.8,0.7,6.4-2.2,8c-0.9,0.5-1.9,0.8-2.9,0.8
		c-2,0-4-1-5.1-2.9c-2.7-4.7-4-9.9-3.9-15.6c0.1-3.2,0.6-6.5,1.6-9.8l-12.2,3.2l2.2-4.4c0.8-1.5,0.9-3.3,0.3-5
		c-0.6-1.6-1.7-3-3.3-3.7l-10.7-5.3c-3.2-1.6-7.1-0.3-8.7,2.9L42.8,73.7L26.5,78c-7.3,1.9-11.6,9.4-9.7,16.7c1.2,4.6,4.6,8,8.7,9.4
		L1.7,152.4c-4,8.2-0.7,18.1,7.5,22.1L73,205.9c2.3,1.2,4.8,1.7,7.3,1.7c6.1,0,12-3.4,14.9-9.2l22.6-45.9c1.7,0.7,3.5,1.1,5.3,1.1
		c1.5,0,3.1-0.3,4.6-0.8c7.1-2.6,10.8-10.4,8.2-17.5L111.7,68.4L111.7,68.4z" />
            <path d="M291.1,218.1L291.1,218.1l-4.4-8.1l-11.4-8.4l0.7-11.3l-6.6-12.3l-6.7-1.3l2.6-10.6l-12.8-3.6l2.7-11.3l-5.7-9.5l-5.9-12.1
		l-8.9-3.1l2.8-8.1l-9.6-17.7l2.2-1.2c2.9-1.6,4-5.2,2.4-8.1l-2-3.8c8.6-15.1,8.9-33.7,0.6-49.1c-3.2-6-7.6-11.2-12.8-15.5
		c-5.1-4.1-10.9-7.1-17.2-9c-6.3-1.9-12.8-2.5-19.3-1.9c-6.8,0.6-13.2,2.6-19.2,5.8c-1.7,0.9-3.4,2-5,3.1c3.4,0.8,6.6,2,9.5,3.6
		c0,0,0.1,0,0.1,0c7.4-2.9,16,0.1,19.9,7.3c2.1,3.8,2.5,8.4,1.2,12.5c-1.3,4-4,7.2-7.7,9c-2.3,1.2-4.9,1.8-7.4,1.8
		c-1.6,0-3.1-0.2-4.7-0.7c-4.1-1.2-7.4-3.9-9.4-7.6c-2-3.7-2.5-8-1.3-12.1c0.2-0.5,0.3-1,0.5-1.5c-2.4-0.9-5.1-1.5-8-1.7
		c-1.2-0.1-2.5-0.2-3.8-0.1c-3.8,4.9-6.6,10.4-8.4,16.4c-1.9,6.3-2.5,12.8-1.9,19.3c0.6,6.8,2.6,13.2,5.8,19.2
		c4.8,9,12.1,16.2,21.2,20.9c7.7,4,16.3,6,25,5.7l0.9,1.7c1.1,2,3.1,3.1,5.3,3.1c1,0,1.9-0.2,2.8-0.7l1.8-1L262.2,234l22,6l7.1-21.6
		L291.1,218.1L291.1,218.1z M281.8,235.6c-0.3,0.2-0.7,0.2-1,0.2c-0.8,0-1.5-0.4-1.9-1.1l-63.5-118.6c-0.5-1-0.2-2.3,0.9-2.9
		c1-0.5,2.3-0.2,2.9,0.9l63.5,118.6C283.2,233.8,282.8,235.1,281.8,235.6L281.8,235.6z" />
            <path d="M179.5,46.8c-0.4-3.7-1.5-7.2-3.4-10.3c-2.5-4.3-6.1-7.7-10.6-10.3c-4.3-2.4-9.3-3.8-14.9-4.3c-11.2-0.9-23.5,2.1-34.7,8.6
		s-20,15.6-24.8,25.8c-2.4,5.1-3.6,10.1-3.8,15.1c-0.1,5.2,1.1,10.1,3.6,14.4c0.5,0.9,1.1,1.8,1.8,2.6c0.7,0.8,1.7,1.2,2.6,1.2
		c0.8,0,1.6-0.3,2.3-0.9c1.5-1.3,1.6-3.5,0.3-4.9c-0.4-0.5-0.8-1-1.1-1.5c-3.7-6.3-3.5-14.5,0.5-23c4.2-8.9,12-16.9,21.9-22.7
		s20.9-8.5,30.6-7.6c9.3,0.8,16.5,4.7,20.1,11.1c1.3,2.3,2.2,4.9,2.5,7.6c0.2,1.7,1.7,3,3.4,3.1C178.1,50.7,179.7,48.9,179.5,46.8
		L179.5,46.8z" />
            <path
                d="M123.1,93.3l2.4,6.8c6.4-0.6,12.9-2.4,19.2-5.1c-1.5-2.1-2.9-4.4-3.8-5.9C134.9,91.5,128.9,93,123.1,93.3z" />
        </g>
    </svg>
</template>