import { ref } from "vue";
import { defineStore } from "pinia";
import { ApplicantListFilters } from "@/types/applicants/ApplicantListFilter";
import { Mutex } from "async-mutex";
import { readCookie } from "@/utils/cookies/JsonCookieReader";
import { writeCookie } from "@/utils/cookies/JsonCookieWriter";
import { useCurrentUserStore } from "../CurrentUserStore";

export const useApplicantListFiltersStore = defineStore('applicantListFilters', () => {
    const mutex = new Mutex();

    const cachedFilters = ref<ApplicantListFilters>();
    const currentUserStore = useCurrentUserStore();
    
    /** Constructs filter name for currently logged in user and site prefix */
    const getFilterName = async () => {
        const filterName = 'x-applicant-list-filters';
        const currentUser = await currentUserStore.getCurrentUserDetails();
        const sitePrefix = await currentUserStore.getSitePrefix();

        return [filterName, sitePrefix, currentUser.id].filter(item => !!item).join('-');
    };

    /** Gets the previous Applicant List filters */
    const getFilters = async () => {
        const filters = await mutex.runExclusive(async () => {
            // To improve performance, only read the cookie we don't already have the data
            if (!cachedFilters.value) {
                cachedFilters.value = readCookie<ApplicantListFilters>(await getFilterName());
            }

            return cachedFilters.value;
        });

        return filters;
    }
    
    /** Stores the current Applicant List filters so filters are remembered 
     * when user navigates back to the Applicant List */
    const updateFilters = async (filters :ApplicantListFilters) => {
        await mutex.runExclusive(async () => {
            /* Improve performance by caching the updated filters
             * This means the app won't need to read the cookie unnecessarily */
            cachedFilters.value = filters;

            // Update cookie so filters are remembered when user reloads the app
            writeCookie<ApplicantListFilters>(await getFilterName(), filters);
        });
    }

    /** Clears the current Task List filters cache */
    const clearCache = () => cachedFilters.value = undefined;

    return { getFilters, updateFilters, clearCache}
});