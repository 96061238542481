import { toastBaseAlert } from '@/alerts/toastBaseAlert';

/*** Displays an error toast
 * @param text error message to be displayed
 * @param displayTime defines how long the toast is displayed in milliseconds. This is set to 6000 by default */
export default function displayErrorMessage(text: string, displayTime: number = 6000) {
    return toastBaseAlert.fire({
        text: text,
        icon: 'error',
        timer: displayTime,
        background: '#f44336'
    });
}