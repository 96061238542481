<script>
/** This component is a converted version of the AutoAddress.vue component provided by the autoaddress.ie/autoaddress-vue
 *  The component provided by AutoAddress is designed to work with Vue 2 and hasn't been updated since 2021
 *  Unfortunately this causes compiler errors when the render() function is parsed
 *  Having searched online for a solution over several hours, I decided the easiest solution was to create my own copy
 *  As the component isn't listed as open source on Github, I was unable to fork the project and republish to npm
 *  Instead, I copied the component and made the minor tweaks to make it work for this project 
 *  As the component works, rather than spend time converting it to the composition api, I've left the it using the options api
 *   - JK 20/11/2023 */

import VanillaAutoaddress from '@autoaddress.ie/autoaddress-vue/src/vanilla-js/autoaddress.vanilla';

// export component
export default {
    name: "AutoAddress",
    methods: {
        setVanityMode(vanityMode) {
            return this.autoaddress.setVanityMode(vanityMode);
        },
        setLanguage(language) {
            return this.autoaddress.setLanguage(language);
        },
        setCountry(country) {
            return this.autoaddress.setCountry(country);
        },
        setProfileName(profileName) {
            return this.autoaddress.setProfileName(profileName);
        },
        setGeographicAddress(geographicAddress) {
            return this.autoaddress.setGeographicAddress(geographicAddress);
        },
        setAddressElements(addressElements) {
            return this.autoaddress.setAddressElements(addressElements);
        },
        setLimit(limit) {
            return this.autoaddress.setLimit(limit);
        },
        setLabel(labelName, text) {
            return this.autoaddress.setLabel(labelName, text);
        },
        showAutocomplete() {
            return this.autoaddress.showAutocomplete();
        },
        setDisplayBusinessListings(displayBusinessListings) {
            return this.autoaddress.setDisplayBusinessListings(
                displayBusinessListings
            );
        },
        clearAddress() {
            return this.autoaddress.clearAddress();
        },
        setApiDomain() {
            if (this.api_domain == (null || "" || undefined)) {
                return "https://api.autoaddress.ie/2.0";
            } else {
                return this.api_domain;
            }
        },
    },
    props: ['licencekey', 'addressProfile', 'vanityMode', 'language', 'country', 'autocomplete', 'autocompleteMinChars', 'optionsLimit',
        'geographicAddress', 'addressElements', 'width', 'displayBusinessListings', 'defaultSearch', 'placeHolderLabel', 'searchButtonLabel',
        'optionsFoundLabel', 'incompleteAddressLabel', 'partialAddressLabel', 'nuaAddressFoundLabel', 'noResultFoundLabel', 'addressFoundLabel',
        'inputRequiredLabel', 'errorMessageLabel', 'addressTabLabel', 'businessTabLabel', 'noEircodeAppendedLabel'],
    watch: {
        addressProfile(value) {
            this.setProfileName(value);
        },
        vanityMode(value) {
            this.setVanityMode(value);
        },
        language(value) {
            this.setLanguage(value);
        },
        country(value) {
            this.setCountry(value);
        },
        geographicAddress(value) {
            this.setGeographicAddress(value);
        },
        addressElements(value) {
            this.setAddressElements(value);
        },
        optionsLimit(value) {
            this.setLimit(value);
        },
        displayBusinessListings(value) {
            this.setDisplayBusinessListings(value);
        },
    },
    mounted() {
        this.el = this.$refs.vueref0;
        this.autoaddress = new VanillaAutoaddress(this.el, {
            key: this.licencekey,
            api_domain: this.setApiDomain(),
            // General Properties
            vanityMode: this.vanityMode,
            language: this.language,
            country: this.country,
            autocomplete: this.autocomplete,
            autocompleteMinChars: this.autocompleteMinChars,
            optionsLimit: this.optionsLimit,
            geographicAddress: this.geographicAddress,
            addressElements: this.addressElements,
            addressProfile: this.addressProfile,
            width: this.width,
            displayBusinessListings: this.displayBusinessListings,
            defaultSearch: this.defaultSearch,
            //	Message Properties
            placeHolderLabel: this.placeHolderLabel,
            searchButtonLabel: this.searchButtonLabel,
            optionsFoundLabel: this.optionsFoundLabel,
            incompleteAddressLabel: this.incompleteAddressLabel,
            partialAddressLabel: this.partialAddressLabel,
            nuaAddressFoundLabel: this.nuaAddressFoundLabel,
            noResultFoundLabel: this.noResultFoundLabel,
            addressFoundLabel: this.addressFoundLabel,
            inputRequiredLabel: this.inputRequiredLabel,
            errorMessageLabel: this.errorMessageLabel,
            addressTabLabel: this.addressTabLabel,
            businessTabLabel: this.businessTabLabel,
            noEircodeAppendedLabel: this.noEircodeAppendedLabel,
            // Events
            onInitialize: () => { this.$emit("onInitialize"); },
            onAutoCompleteSelected: (item) => { this.$emit("onAutoCompleteSelected", item); },
            onSearchStart: () => { this.$emit("onSearchStart"); },
            onSearchCompleted: (data) => { this.$emit("onSearchCompleted", data); },
            onLookupError: () => { this.$emit("onLookupError"); },
            onAddressFound: (data) => { this.$emit("onAddressFound", data); },
            // User Input Callbacks
            onChange: (item) => { this.$emit("onChange", item); },
            onClear: () => { this.$emit("onClear"); },
            onFocus: () => { this.$emit("onFocus"); }
        });
    },
    unmounted() {
        // this.autoaddress('destroy');
        console.log(`componentWillUnmount ${this.el}`);
    }
};
</script>
<template>
    <div ref="vueref0"></div>
</template>
<style>
@import '../../../assets/auto-address/css/autoaddress.css';
</style>