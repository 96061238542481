import Swal from "sweetalert2";

export const toastBaseAlert = Swal.mixin({
    customClass: {
        icon: 'acquaint-swal-icon',
        container: 'acquaint-swal-container',
        popup: 'acquaint-swal-popup',
        htmlContainer: 'acquaint-toast-swal-html-container',
        confirmButton: 'acquaint-swal-confirm-button'
    },
    timerProgressBar: true,
    iconColor: 'white',
    color: 'white',
    position: 'top-right',
    toast: true,
    showConfirmButton: false
});