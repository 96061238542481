import { AcquaintApiClient } from '@/services/AcquaintApiClient';
import { Agent } from '@/types/utility-switching/Agent';
import { Mutex } from 'async-mutex';
import { defineStore } from 'pinia'
import { inject, ref } from 'vue';

export const useAgentsStore = defineStore('agentsStore', () => {
    const mutex = new Mutex();

    const cachedAgents = ref<Agent[]>();
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient;

    /** Retrieves all Utility Switching Agents */
    const getAgents = async () => {
        const agents = await mutex.runExclusive(async () => {
            // To improve app performance only call api if agent data isn't currently cached
            if (!cachedAgents.value) {
                cachedAgents.value = await getAgentsFromApi();
            }

            return cachedAgents.value;
        });

        return agents;
    }

    /** Retrieves Utility Switching Agents from the Acquaint Api */
    const getAgentsFromApi = async () => {
        return await apiClient.httpGet<Agent[]>('app/utilityswitching/agents/list');
    }

    const returnAgentName = async (id:string)=>{
        if(!cachedAgents.value){
            await getAgents()
        }

        if(!cachedAgents.value){
            return
        }

        const selectedAgent:Agent[] = cachedAgents.value.filter((agentPair)=>{
            agentPair.agentID == id;
        });

        return selectedAgent[0].name;

    };

    // const getAgentsName

    /** Clears the cached Agent data */
    const clearCache = () => cachedAgents.value = undefined;

    return { getAgents, clearCache, returnAgentName }
});