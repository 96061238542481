import { defineStore } from "pinia";
import { inject, ref } from "vue";
import { Mutex } from "async-mutex";
import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { SwitchStatus } from "@/types/utility-switching/SwitchStatus";
import { IDDescriptionPair } from "@/types/generic/IDDescriptionPair";

export const useSwitchStatusesStore = defineStore('switchStatusesStore', () => {
    const mutex = new Mutex();
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient
    const cachedStatuses = ref<IDDescriptionPair[]>();

    /** Retrieves all switch statuses */
    const getSwitchStatuses = async () => {
        const statuses = await mutex.runExclusive(async () => {
            // To improve app performance only call api if data isn't currently cached
            if (!cachedStatuses.value) {
                const rawStatuses = await getSwitchStatusesFromApi();
                cachedStatuses.value = mapRawSwitchStatusesToIDDescriptionPair(rawStatuses);
            }

            return cachedStatuses.value;
        });

        return statuses;
    }

    /** Retrieves all switch statuses from the Acquaint Api */
    const getSwitchStatusesFromApi = async () =>  await apiClient.httpGet<SwitchStatus[]>('app/utilityswitching/switches/statuses');

    /** Converts the passed array of Switch Statuses to an ID Description Pair
     * @param rawStatuses - The Switch Statuses to convert */
    const mapRawSwitchStatusesToIDDescriptionPair = (rawStatuses: SwitchStatus[]) :IDDescriptionPair[] => {
        return rawStatuses.map<IDDescriptionPair>(item => { return {id: item.sortIndex, description: item.value} });
    }

    /** Clears the cached switch status data */
    const clearCache = () => cachedStatuses.value = undefined;

    return { getSwitchStatuses, clearCache }
});