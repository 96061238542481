import { Address } from "@/types/generic/Address";

const getDefaultAddressValue = (): Address => {
    return {
        countryCode: 0,
        postCode: '',
        propertyName: '',
        street: '',
        locality: '',
        town: '',
        region: '',
        country: ''
    };
}

const formatAddress = (propertyName: string = '', street: string = '', locality: string = '', town: string = '', region: string = '', postCode: string = '', country: string = '', delimiter: string = ', '): string => {
    return [propertyName, street, locality, town, region, postCode, country]
        .filter(item => !!item)
        .join(delimiter);
}

export { getDefaultAddressValue, formatAddress }