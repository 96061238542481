import { EnergyTypes } from '@/enums/utility-switching/EnergyTypes';

export function AddressEnergyTypeMapper(gasFuel:number, electricityFueld:number){
    if(gasFuel && electricityFueld){
        return EnergyTypes.DualFuel;
    } else if (!electricityFueld && gasFuel){
        return EnergyTypes.GasOnly;
    } else if (electricityFueld && !gasFuel){
        return EnergyTypes.ElectricityOnly;
    } else{
        return EnergyTypes.ElectricityOnly;
    }
}