import { ref } from "vue";
import { defineStore } from "pinia";
import { ContactListFilters } from "@/types/contacts/ContactListFilters";
import { Mutex } from "async-mutex";
import { readCookie } from "@/utils/cookies/JsonCookieReader";
import { writeCookie } from "@/utils/cookies/JsonCookieWriter";
import { useCurrentUserStore } from "../CurrentUserStore";

export const useContactListFiltersStore = defineStore('contactListFilters', () => {
    const mutex = new Mutex();

    const cachedFilters = ref<ContactListFilters>();
    const currentUserStore = useCurrentUserStore();
    
    /** Constructs filter name for currently logged in user and site prefix */
    const getFilterName = async () => {
        const filterName = 'x-contact-list-filters';
        const currentUser = await currentUserStore.getCurrentUserDetails();
        const sitePrefix = await currentUserStore.getSitePrefix();

        return [filterName, sitePrefix, currentUser.id].filter(item => !!item).join('-');
    };

    /** Gets the previous Contact List filters */
    const getFilters = async () => {
        const filters = await mutex.runExclusive(async () => {
            // To improve performance, only read the cookie we don't already have the data
            if (!cachedFilters.value) {
                cachedFilters.value = readCookie<ContactListFilters>(await getFilterName());
            }

            return cachedFilters.value;
        });

        return filters;
    }

    /** Stores the current Contact List filters so filters are remembered 
     * when user navigates back to the Contact List */
    const updateFilters = async (filters: ContactListFilters) => {
        await mutex.runExclusive(async () => {
            /* Improve performance by caching the updated filters
             * This means the app won't need to read the cookie unnecessarily */
            cachedFilters.value = filters;

            // Update cookie so filters are remembered when user reloads the app
            writeCookie<ContactListFilters>(await getFilterName(), filters);
        });
    }

    /** Clears the current filters cache */
    const clearCache = () => cachedFilters.value = undefined;

    return { getFilters, updateFilters, clearCache }
});