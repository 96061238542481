<script setup lang="ts">
</script>
<template>
    <div class="tab-panel-container">
        <slot></slot>
    </div>
</template>
<style scoped>
.tab-panel-container {
    padding: 1rem;
}
</style>