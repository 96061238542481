import Cookies from "js-cookie";

/** Serializes the passed data as json and stores it in a secure cookie */
export const writeCookie = <T>(cookieName: string, data: T, expiryDays: number|undefined = 31) => {
    // Sanity check the cookie name to avoid errors
    if (!cookieName) {
        throw 'cookieName must be entered';
    }

    // Max expiry date for cookies in IOS and Chrome is 400 days
    const cookieOptions: Cookies.CookieAttributes = ({ sameSite: 'Strict', secure: true });

    if(expiryDays) {
        cookieOptions.expires = expiryDays;
    }

    // To avoid potential issues with old cookies, clear previous cookie with the same name
    try {
        Cookies.remove(cookieName)
    } catch {
        // Ignore errors if the cookie doesn't exist
    }

    Cookies.set(cookieName, JSON.stringify(data), cookieOptions);
}