import Cookies from "js-cookie";
import router from '../router'
import { LoginResponse } from "@/types/authorization/LoginResponse"
import { SiteDetails } from "../types/authentication/SiteDetails";
import { UserDetails } from "../types/authentication/UserDetails";
import { RouteNames } from "@/router/RouteNames";

// A class for managering the authcookie
export class AuthenticationCookieManager {
    private _databaseConnectorTokenName = "acquaint-app-database-connector"
    private _siteDetails = "acquaint-app-site-details"
    private _userDetails = "acquaint-app-user-details"
    public getApiDetails(): LoginResponse | undefined {

        // Trys to read the cookie and returns the details if successful
        try {
            const authenticationDetails = Cookies.get(this._databaseConnectorTokenName) as string;

            return JSON.parse(authenticationDetails) as LoginResponse;
        } catch {
            throw new Error("Unable to read login details cookie");
        }
    }
    public getDatabaseConnectorToken(): LoginResponse | undefined {
        try {
            const databaseConnectorToken = this.readCookie(this._databaseConnectorTokenName);

            return JSON.parse(databaseConnectorToken) as LoginResponse;
        } catch {
            return undefined
        }
    }
    public getSiteDetails(): SiteDetails | undefined {
        // Trys to read the cookie and returns the details if successful
        try {
            const siteDetails = this.readCookie(this._siteDetails);

            return JSON.parse(siteDetails) as SiteDetails;
        } catch {
            return undefined
        }
    }
    public getUserDetails(): UserDetails | undefined {
        try {
            const userDetails = this.readCookie(this._userDetails);

            return JSON.parse(userDetails) as UserDetails;
        } catch {
            return undefined
        }
    }

    public resetState() {
        Cookies.remove(this._databaseConnectorTokenName);

        router.push({ name: RouteNames.LoginForm });
    }

    public storeSiteDetails(siteDetails: SiteDetails) {
        const cookieOptions: Cookies.CookieAttributes = (siteDetails.rememberMe ? { sameSite: 'Strict', expires: 31, secure: this.useSecureCookies() } : { sameSite: 'Strict', secure: this.useSecureCookies() });

        Cookies.set(this._siteDetails, JSON.stringify(siteDetails), cookieOptions);
    }

    public storeLogin(apiAuthenticationToken: LoginResponse) {
        Cookies.set(this._databaseConnectorTokenName, JSON.stringify(apiAuthenticationToken), { sameSite: 'Strict', secure: this.useSecureCookies() });
    }

    private readCookie(cookieName: string) {
        // Trys to read the cookie and returns the details if successful
        const cookieValue = Cookies.get(cookieName);

        if (cookieValue == undefined) {
            return '';
        }

        return cookieValue;
    }

    /** Determines whether cookies should be stored securely */
    private useSecureCookies() {
        /* Don't use secure cookies for devs testing on bs-local 
         * Otherwise users can't get past the login screen */
        return window.location.hostname != 'bs-local.com';
    }
}