<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Support_Cases_copy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 247.8 240" style="enable-background:new 0 0 247.8 240;" xml:space="preserve" width="100%" height="100%">
        <g>
            <polygon points="247.8,212.4 218.7,212.4 218.7,240 192.6,240 192.6,212.4 163.5,212.4 163.5,187.2 192.6,187.2 192.6,159.6 
		218.7,159.6 218.7,187.2 247.8,187.2 	" />
            <path
                d="M177.6,71.7c-1.5-18.5-9.4-35.7-22.6-48.9C140.3,8.1,120.7,0,99.8,0S60.5,7.7,45.9,21.6C31.9,34.9,23.5,52.6,22,71.7
		C9.4,74.5,0,85.6,0,99v25.4c0,15.4,12.5,27.9,27.9,27.9c13.9,0,25.2-11.3,25.2-25.2V96.3c0-8.6-4.3-16.2-10.9-20.7
		c0.1-0.3,0.2-0.7,0.2-1.1c1.5-30.6,26.7-54.6,57.5-54.6c15.4,0,29.8,6,40.7,16.8c10.1,10.1,16.1,23.6,16.8,37.8
		c0,0.4,0.1,0.7,0.2,1.1c-6.6,4.5-10.9,12.1-10.9,20.7v30.8c0,11.6,8,21.5,18.7,24.3v7c0,10.7-8.6,19.5-19.3,19.5h-27.8
		c-1.5-8.8-9.2-15.6-18.5-15.6c-10.3,0-18.7,8.4-18.7,18.7c0,10.3,8.4,18.7,18.7,18.7c9.3,0,17-6.8,18.5-15.6H146
		c14.1,0,25.5-11.5,25.5-25.7v-6.1c0.1,0,0.1,0,0.2,0c15.4,0,27.9-12.5,27.9-27.9V99C199.6,85.6,190.2,74.4,177.6,71.7L177.6,71.7z" />
        </g>

    </svg>
</template>