import { Address } from '@/types/generic/Address';
import { EnergyDetails } from '@/types/switches/EnergyDetails';
import { PersonalInfo } from '@/types/switches/PersonalInfo';
import { LandlordPersonalInfo } from '@/types/switches/LandlordPersonalInfo';
import { EnergyTypes } from '@/enums/utility-switching/EnergyTypes';
import { PurchaserPersonalInfo } from '@/types/switches/PurchaserPersonalInfo';

const CollatePropertySwitchTypeReadingsData = (propertyAddress: Address, energyDetails: EnergyDetails, energyType: number, switchPropertyId: number) => {
    return {
        property: {
            address: {
                countryCode: propertyAddress.countryCode,
                postCode: propertyAddress.postCode,
                propertyName: propertyAddress.propertyName,
                street: propertyAddress.street,
                locality: propertyAddress.locality,
                town: propertyAddress.town,
                region: propertyAddress.region,
                country: propertyAddress.country
            },
            mprn: energyDetails.mprn,
            gprn: energyType != EnergyTypes.ElectricityOnly ? energyDetails.gasGPRNNum : '',
            electricMeterType: energyDetails.electricMeterType,
            payAsYouGoGasMeter: energyType != EnergyTypes.ElectricityOnly && energyDetails.payAsYouGo,
            propertyId: switchPropertyId
        },
        switchType: energyType,
        meterReadings: {
            twentyFourHourMeterRead: energyType != EnergyTypes.GasOnly ? Number(energyDetails.twentyfourHMeterRead) : 0,
            nightSaverMeterRead: energyType != EnergyTypes.GasOnly ? Number(energyDetails.nightSaverMeterRead) : 0,
            dayMeterRead: energyType != EnergyTypes.GasOnly ? Number(energyDetails.dayMeterMeterRead) : 0,
            nightMeterRead: energyType != EnergyTypes.GasOnly ? Number(energyDetails.nightMeterRead) : 0,
            peakMeterRead: energyType != EnergyTypes.GasOnly ? Number(energyDetails.peakMeterRead) : 0,
            meterReadingDate: energyDetails.readingDate,
            gasMeterRead: energyType != EnergyTypes.ElectricityOnly ? Number(energyDetails.gasMeterRead) : 0
        }
    }
};

export const CollateTenantSubmissionData = (propertyAddress: Address, energyDetails: EnergyDetails, energyType: number, switchPropertyId: number, tenantPersonalInfo: PersonalInfo, landlordPersonalInfo: LandlordPersonalInfo, tenancyId: number) => {
    const propertySwitchTypeReadingsData = CollatePropertySwitchTypeReadingsData(propertyAddress, energyDetails, energyType, switchPropertyId);
    const tenantSwitchSpecificData = {
        tenancyStartDate: tenantPersonalInfo.tenancyStartDate,
        tenant: {
            titleId: tenantPersonalInfo.title,
            firstName: tenantPersonalInfo.firstName,
            lastName: tenantPersonalInfo.lastName,
            telephoneNumber: tenantPersonalInfo.number,
            email: tenantPersonalInfo.email,
            dateOfBirth: tenantPersonalInfo.birthDate,
            criticalCare: tenantPersonalInfo.priorityService,
            tenantId: tenantPersonalInfo.id
        },
        landlord: {
            titleId: landlordPersonalInfo.title,
            firstName: landlordPersonalInfo.firstName,
            lastName: landlordPersonalInfo.lastName,
            telephoneNumber: landlordPersonalInfo.number,
            email: landlordPersonalInfo.email,
            address: {
                countryCode: landlordPersonalInfo.address.countryCode,
                postCode: landlordPersonalInfo.address.postCode,
                propertyName: landlordPersonalInfo.address.propertyName,
                street: landlordPersonalInfo.address.street,
                locality: landlordPersonalInfo.address.locality,
                town: landlordPersonalInfo.address.town,
                region: landlordPersonalInfo.address.region,
                country: landlordPersonalInfo.address.country
            },
            landlordId: landlordPersonalInfo.id
        },
        tenancyId: tenancyId
    };

    return { ...tenantSwitchSpecificData, ...propertySwitchTypeReadingsData };
};

export const CollateAllocationSubmissionData = (propertyAddress: Address, energyDetails: EnergyDetails, energyType: number, switchPropertyId: number, tenantPersonalInfo: PersonalInfo) => {
    const propertySwitchTypeReadingsData = CollatePropertySwitchTypeReadingsData(propertyAddress, energyDetails, energyType, switchPropertyId);
    const tenantSwitchSpecificData = {
        tenancyStartDate: tenantPersonalInfo.tenancyStartDate,
        tenant: {
            titleId: tenantPersonalInfo.title,
            firstName: tenantPersonalInfo.firstName,
            lastName: tenantPersonalInfo.lastName,
            telephoneNumber: tenantPersonalInfo.number,
            email: tenantPersonalInfo.email,
            dateOfBirth: tenantPersonalInfo.birthDate,
            criticalCare: tenantPersonalInfo.priorityService,
            tenantId: tenantPersonalInfo.id
        }
    };

    return { ...tenantSwitchSpecificData, ...propertySwitchTypeReadingsData };
};

export const CollateLandlordSubmissionData = (propertyAddress: Address, energyDetails: EnergyDetails, energyType: number, switchPropertyId: number, landlordPersonalInfo: LandlordPersonalInfo, billTo: number, tenancyId: number) => {
    const propertySwitchTypeReadingsData = CollatePropertySwitchTypeReadingsData(propertyAddress, energyDetails, energyType, switchPropertyId);
    const landlordSwitchSpecificData = {
        landlord: {
            titleId: landlordPersonalInfo.title,
            firstName: landlordPersonalInfo.firstName,
            lastName: landlordPersonalInfo.lastName,
            telephoneNumber: landlordPersonalInfo.number,
            email: landlordPersonalInfo.email,
            address: {
                countryCode: landlordPersonalInfo.address.countryCode,
                postCode: landlordPersonalInfo.address.postCode,
                propertyName: landlordPersonalInfo.address.propertyName,
                street: landlordPersonalInfo.address.street,
                locality: landlordPersonalInfo.address.locality,
                town: landlordPersonalInfo.address.town,
                region: landlordPersonalInfo.address.region,
                country: landlordPersonalInfo.address.country
            },
            landlordId: landlordPersonalInfo.id
        },
        billTo: billTo,
        tenancyId: tenancyId
    };

    return { ...landlordSwitchSpecificData, ...propertySwitchTypeReadingsData }
};

export const CollatePurchaserSubmissionData = (propertyAddress: Address, energyDetails: EnergyDetails, energyType: number, switchPropertyId: number, purchaser: PurchaserPersonalInfo) => {
    const propertySwitchTypeReadingsData = CollatePropertySwitchTypeReadingsData(propertyAddress, energyDetails, energyType, switchPropertyId);

    const purchaserSwitchSpecificData = {
        purchaser: {
            titleId: purchaser.title,
            firstName: purchaser.firstName,
            lastName: purchaser.lastName,
            telephoneNumber: purchaser.number,
            email: purchaser.email,
            dateOfBirth: purchaser.birthDate,
            criticalCare: purchaser.priorityService,
            purchaserId: purchaser.id
        }
    }
    return { ...purchaserSwitchSpecificData, ...propertySwitchTypeReadingsData }
};

export const CollateCouncilLandlordSubmissionData = (propertyAddress: Address, energyDetails: EnergyDetails, energyType: number, switchPropertyId: number, isNewDevelopment: boolean) => {
    const propertySwitchTypeReadingsData = CollatePropertySwitchTypeReadingsData(propertyAddress, energyDetails, energyType, switchPropertyId);

    return { ...propertySwitchTypeReadingsData, isNewDevelopment: isNewDevelopment };
};