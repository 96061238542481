export enum FieldSize {
    XXSmall = '4rem',
    XSmall = '6rem',
    Small = '8rem',
    Smallish = '9rem',
    SmallMedium = '10rem',
    Medium = '12rem',
    Large = '16rem',
    XLarge = '22rem',
    XXLarge = '30rem',
    Full = '100%'
}