<script setup lang="ts">
</script>
<template>
    <svg version="1.1" id="Layer_84" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve" height="100%" width="100%">
        <g>
            <path
                d="M213.6,86.3c-1.8-22.2-11.3-43-27.2-58.8C168.6,9.8,145.1,0,120,0S72.7,9.2,55.2,26C38.4,42,28.3,63.3,26.4,86.3
		C11.4,89.5,0,102.9,0,119v30.5C0,168,15,183,33.5,183c16.7,0,30.3-13.6,30.3-30.3v-37c0-10.3-5.2-19.5-13.2-24.9
		c0.2-0.4,0.3-0.8,0.3-1.3C52.7,52.7,83,23.8,120,23.8c18.5,0,35.9,7.2,48.9,20.2c12.2,12.2,19.4,28.3,20.2,45.5
		c0,0.5,0.1,0.9,0.3,1.3c-7.9,5.5-13.2,14.6-13.2,24.9v37c0,14,9.6,25.8,22.5,29.2v8.4c0,12.9-10.4,23.4-23.2,23.4h-33.4
		c-1.8-10.6-11.1-18.7-22.2-18.7c-12.4,0-22.5,10.1-22.5,22.5c0,12.4,10.1,22.5,22.5,22.5c11.1,0,20.4-8.1,22.2-18.7h33.4
		c16.9,0,30.7-13.9,30.7-30.9V183c0.1,0,0.2,0,0.3,0c18.5,0,33.5-15,33.5-33.5V119C240,102.9,228.7,89.5,213.6,86.3L213.6,86.3z" />
        </g>
    </svg>
</template>