import { RouteNames } from "@/router/RouteNames";
import { IHelpNavigator } from "@/types/services/IHelpNavigator";
import { App, Plugin } from "vue";

export class HelpNavigator implements IHelpNavigator {
    private readonly _baseUrl = 'https://acquaintweb.scrollhelp.site/home';

    public LoadHelpTopicByPageName(pageName: string): void {
        window.open(`${this._baseUrl}/${pageName}`)
    }

    public LoadHelpTopicFromCurrentRoute(): void {    
        this.LoadHelpTopicByPageName(this.getHelpPageNameFromCurrentRouteName());
    }

    /** Gets the help topic page name from the current route */
    private getHelpPageNameFromCurrentRouteName() {
        const route = window.location.pathname.substring(1);

        switch (route) {
            case RouteNames.LoginForm:
                return 'sign-in';
            case RouteNames.ResetPassword:
            case RouteNames.ResetPasswordConfirmation:
                return 'forgotten-password';
            case RouteNames.ContactList:
            case RouteNames.ContactPage:
            case RouteNames.Applicants:
                return 'contacts';
            case RouteNames.Configuration:
                return 'system-configuration';
            case RouteNames.CertificateList:
            case RouteNames.CertificatePage:
                return 'certificates';
            case RouteNames.Diary:
                return 'diary';
            case RouteNames.Properties:
            case RouteNames.PropertyPage:
                return 'properties';
            case RouteNames.PropertyOffers:
                return 'property-offers';
            default:
                return '';
        }
    }
}

export const HelpNavigatorPlugin: Plugin = {
    install: (app: App) => {
        const navigator = new HelpNavigator();
        app.provide('HelpNavigator', navigator);
    }
}