import { defineStore } from "pinia";
import { reactive } from "vue";
import { RecordNavigator } from '../../utils/navigation/RecordNavigator'

export const usePropertyRecordNavigationStore = defineStore('propertyRecordNavigationStore', () => {
    // Helper class used to navigate property records. 
     const recordNavigator = reactive<RecordNavigator>(new RecordNavigator)

    /** Cache passed array of property ids */
    const cacheProperties = (properties :number[], currentIndex :number = 0) => {
        recordNavigator.cacheRecords(properties, currentIndex)
    } 

    /** Gets the next property in the array */
    const getNextPropertyId = () => recordNavigator.getNextRecord();

    /** Gets the previous property in the array */
    const getPreviousPropertyId = () => recordNavigator.getPreviousRecord();

    /** Checks whether the current property is the first property in the array */
    const isFirstProperty = () => recordNavigator.isFirstRecord();

    /** Checks whether the current property is the last property in the array */
    const isLastProperty = () => recordNavigator.isLastRecord();

    /** Gets the current position in the array */
    const currentPosition = () => recordNavigator.currentPosition();

    /** Gets the total count of records in the array */
    const totalCount = () => recordNavigator.totalCount();

    /** Checks whether the cache is empty */
    const isEmpty = () => recordNavigator.isEmpty();

    /** Clears the cached array of property ids */
    const clear = () => recordNavigator.clear();

    return { cacheProperties, getNextPropertyId, getPreviousPropertyId, isFirstProperty, isLastProperty, currentPosition, totalCount, isEmpty, clear }
});