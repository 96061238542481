import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { ContactType } from '@/types/ContactType';
import { Mutex } from "async-mutex";
import { defineStore } from "pinia";
import { inject, ref } from "vue";

export const useContactTypeConfigurationStore = defineStore('systemCotactType', () => {
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient;
    const mutex = new Mutex();
    const systemContactTypeCache = ref<ContactType[]>([]);

    /** Gets the system type of the passed contact type id */
    const getSystemContactType = async (id: number) => {
        const contactType = await getContactType(id);
        return contactType.typeID;
    };

    const getContactType = async (id: number) => {
        const type = await mutex.runExclusive(async () => {
            let cachedType = systemContactTypeCache.value.find(x => x.id === id);

            if (cachedType) {
                return cachedType;
            }

            cachedType = await getContactTypeFromApi(id);
            systemContactTypeCache.value.push(cachedType);
            return cachedType;
        });

        return type;
    }

    const getContactTypeFromApi = async (id: number) => {
        return await apiClient.httpGet<ContactType>(`database/types/${id}`)
    };

    const clearCache = () => systemContactTypeCache.value = [];

    return { getSystemContactType, getContactType, clearCache }
});