import { inject, ref } from "vue";
import { defineStore } from "pinia";
import { IDDescriptionPair } from "@/types/generic/IDDescriptionPair";
import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { Mutex } from "async-mutex";
import { SupportCaseReason } from "@/types/utility-switching/SupportCaseReason";
import { mapSupportCaseReasonsToIDDescriptionPairArray } from "@/utils/utility-switching/support-cases/SupportCaseReasonMapper";
import { SecondarySupportCaseReasonCacheItem } from "@/types/utility-switching/SecondarySupportCaseReasonCacheItem";

export const useSupportCaseReasonsStore = defineStore('supportCaseReasonsStore', () => {
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient;
    const mutex = new Mutex();
    const primaryCaseReasons = ref<IDDescriptionPair[]>();
    const secondaryCaseReasons = ref<SecondarySupportCaseReasonCacheItem[]>([]);

    /** Gets all primary Support Case Reasons */
    const getPrimarySupportCaseReasons = async () => {
        const reasons = mutex.runExclusive(async () => {
            // Improve performance by only calling api if primary case reasons haven't been retrieved previously
            if (!primaryCaseReasons.value) {
                const caseReasons = await getPrimarySupportCaseReasonsFromApi();
                primaryCaseReasons.value = mapSupportCaseReasonsToIDDescriptionPairArray(caseReasons);
            }

            return primaryCaseReasons.value;
        });

        return reasons;
    }

    /** Calls api to retrieve all primary support case reasons */
    const getPrimarySupportCaseReasonsFromApi = async () => {
        return await apiClient.httpGet<SupportCaseReason[]>('app/utilityswitching/supportcases/reasons');
    }

    /** Gets the Secondary support Case Reasons for the passed Primary Support Case Reason
     * @param primaryCaseReasonId Id of the Primary Support Case Reason to retrieve Secondary Support Case Reasons for */
    const getSecondarySupportCaseReasons = async (primaryCaseReasonId :number) => {
        /* Ids are only valid if they're greater than 0
         * To prevent api errors, we should only call the api if the id is valid */
        if (primaryCaseReasonId < 0) {
            return [];
        }

        // To improve performance and avoid unnecessary api calls, check the cache for secondary case reasons first
        const secondaryCaseReasonCacheItem = secondaryCaseReasons.value.find(item => item.primarySupportCaseReasonId === primaryCaseReasonId);
        
        if (secondaryCaseReasonCacheItem) {
            return secondaryCaseReasonCacheItem.values;
        }

        // Secondary reasons aren't in the cache, so call api and cache data to improve performance of future lookups
        const caseReasons = await getSecondarySupportCaseReasonsFromApi(primaryCaseReasonId);
        const mappedCaseReasons = mapSupportCaseReasonsToIDDescriptionPairArray(caseReasons);

        secondaryCaseReasons.value.push({
            primarySupportCaseReasonId: primaryCaseReasonId,
            values: mappedCaseReasons
        });

        return mappedCaseReasons;
    }

    /** Calls api to retrieve the secondary support case reasons for the passed primary case reason id
     * @param primaryCaseReasonId Id of the Primary Support Case Reason to retrieve Secondary Support Case Reasons for */
    const getSecondarySupportCaseReasonsFromApi = async (primaryCaseReasonId :number) => {
        return await apiClient.httpGet<SupportCaseReason[]>(`app/utilityswitching/supportcases/reasons/${primaryCaseReasonId}`);
    }

    /** Gets the description for a Primary Support Case Reason
     * @param reasonId Id of the Primary Support Case Reason to get description for */
    const getPrimarySupportCaseReasonDescription = async (reasonId :number) => {
        if (!primaryCaseReasons.value) {
            await getPrimarySupportCaseReasons();
        }

        const record = primaryCaseReasons.value?.find(item => item.id === reasonId);
        return record ? record.description : '';
    }

    /** Gets the description for a Secondary Support Case Reason
     * @param primaryCaseReasonId - Id of the Primary Support Case Reason linked to the Secondary Case Reason
     * @param secondaryCaseReasonId - Id of the Secondary Support Case Reason to retrieve the description for */
    const getSecondarySupportCaseReasonDescription = async (primaryCaseReasonId: number, secondaryCaseReasonId: number) => {
        const secondaryCaseReasons = await getSecondarySupportCaseReasons(primaryCaseReasonId);
        const record = secondaryCaseReasons.find(item => item.id === secondaryCaseReasonId);
        return record ? record.description : '';
    }

    return { getPrimarySupportCaseReasons, getSecondarySupportCaseReasons, getPrimarySupportCaseReasonDescription, getSecondarySupportCaseReasonDescription }
});